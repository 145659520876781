import { Menu, MenuItem, Submenu, Tooltip } from 'element-ui';
import { mapState } from 'vuex';
import { judgingArchiAPermission, judgingCustomLogic, openUrl, checkMenuAuth } from '@/utils/tools';
import { dataInterface } from '@/apis/data';

export default {
	components: {
		'el-menu': Menu,
		'el-submenu': Submenu,
		'el-menu-item': MenuItem,
		'el-tooltip': Tooltip
	},
	data() {
		return {
			menuList: [],
			// 当前选中
			currentPart: null,
			// 当前路由
			currentPath: '',
			// 架构类型
			archiType: ''
		};
	},
	computed: {
		...mapState(['showMenuList', 'currentMenuThree', 'systemConfig', 'currentMenuPart']),
		// 默认展开的数组
		defaultOpened() {
			if (!this.menuList || !this.menuList.length) return [];
			const result = [];
			const path = this.$route.path;
			for (let i = 0; i < this.menuList.length; i++) {
				const item = this.menuList[i];
				const url = item?.menu_path || item?.link;
				// url.indexOf(path) > -1
				if (url && this.isChildPath(url, path)) {
					result.push(item?.menu_path || item?.link);
				} else {
					const children = item?.children_list || [];
					//  children.find(ele => ele[this.urlUUID].indexOf(path) > -1)
					if (
						children &&
						children.length &&
						children.find((ele) => this.isChildPath(ele?.menu_path || ele?.link, path))
					) {
						result.push(item?.menu_path || item?.link);
					}
				}
			}
			return result;
		},
		themeClassName() {
			const { theme_type = 1 } = this.systemConfig || {};
			if (+theme_type === 2) {
				return 'menu-list-two';
			}
			return 'menu-list-one';
		},
		// 名称
		menuName() {
			// return this.currentPart ? this.currentPart.name : this.currentMenuThree?.name;
			// return this.currentMenuThree?.name || this.currentPart?.name || '';
			return (
				this.$getLocalizedLabel({ zhLabel: this.currentMenuThree?.name, enLabel: this.currentMenuThree?.en_name }) ||
				this.$getLocalizedLabel({ zhLabel: this.currentPart?.name, enLabel: this.currentPart?.en_name }) ||
				''
			);
		},
		// 当前三级菜单icon
		menuIcon() {
			return this.currentMenuThree?.icon || this.currentPart?.icon || '';
		}
	},
	watch: {
		$route: {
			handler() {
				this.currentPath = this.$route.fullPath;
			},
			deep: true,
			immediate: true
		},
		currentMenuThree: {
			handler() {
				const children = this.currentMenuThree?.children_list || [];
				this.menuList = this.getMenuByLevel(4, children, []);
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.currentPath = window.location.pathname || '';
		this.archiType = this.$GetTargetArchi('archiType');
	},
	mounted() {
		// 绑定菜单事件
		// this.$nextTick(() => {
		// 	const menuItemList = document.querySelectorAll('.el-menu-item') || [];
		// 	menuItemList.forEach(ele => {
		// 		const config = ele.dataset?.config;
		// 		ele.addEventListener('contextmenu', (e) => {
		// 			this.onContextMenu(JSON.parse(config), e, ele);
		// 		})
		// 	})
		// 	const submenuList = document.querySelectorAll('.el-submenu') || [];
		// 	submenuList.forEach(ele => {
		// 		ele.addEventListener('contextmenu', (e) => {
		// 			e.stopPropagation();
		// 			e.preventDefault();
		// 			this.closeMenu();
		// 		});
		// 	});
		// });
	},
	methods: {
		checkMenuAuth,
		judgingArchiAPermission,
		judgingCustomLogic,
		/**
		 * @desc: 根据指定层级获取菜单列表
		 * @param {Number} level 层级
		 * @param {Array} list 列表
		 * @param {Array} result 遍历结果
		 */
		getMenuByLevel(level, list, result = []) {
			if (isNaN(level) || level < 2) {
				return [];
			}
			if (!list || !Array.isArray(list)) {
				return [];
			}
			list.forEach((ele) => {
				if (ele.level === level && ele.parent_id === this.currentMenuThree.id) {
					result.push(ele);
				}
				if (ele.level < level) {
					const children_list = ele.children_list;
					if (children_list && Array.isArray(children_list) && children_list.length) {
						result = result.concat(this.getMenuByLevel(level, children_list, result));
					}
				}
			});
			return result;
		},
		/**
		 * @desc: 点击菜单
		 * @param {Object} menu 菜单数据
		 */
		async onItem(menuData) {
			console.log(menuData, '----0000---menuData-');
			this.currentPart = menuData;
			this.$store.commit('setCurrentMenu', {
				level: 4,
				menuData
			});
			// 功能组展开下级
			const { menu_type, link, link_type, menu_path, page_uuid } = menuData;
			// 进入应用历史记录
			if ([0, 1, 3, 4].includes(menu_type)) {
				this.$store.commit('setAppNavHistory', {
					origin: 11,
					history: menuData
				});
			}
			if (menu_type === 0) {
				// 页面
				if (this.$route.path === menu_path) return;
				this.$router.push({
					path: menu_path,
					query: {
						// pageId: pageObj.page_id, 暂时移除页面ID
						pageUUID: page_uuid
					}
				});
			} else if (menu_type === 1) {
				// 跳转链接
				let linkUrl = link;
				if (link === 'DATAVIEW') {
					// 大屏
					linkUrl = await this.getDataViewUrl();
				}
				if (!linkUrl) {
					this.$message.error('出错了，请重试!');
					return false;
				}
				if (link_type === '_blank') {
					// window.open(linkUrl);
					openUrl(linkUrl, linkUrl);
				} else if (link_type === '_self') {
					window.location.href = linkUrl;
				} else {
					// window.open(linkUrl);
					openUrl(linkUrl, linkUrl);
				}
			} else if (menu_type === 3) {
				// 暂时弃用
				if (this.$route.path === menu_path) return;
				// 微前端页面
				this.$router.push({
					path: menu_path,
					query: {
						type: 'micro'
					}
				});
			} else if (menu_type === 4) {
				// console.log('仅跳转页面')
				console.log(22222222222, menu_type, `${window.location.origin}${menu_path}`);
				if (this.$route.path === menu_path) return;
				// window.open(`${window.location.origin}${menu_path}`)
				this.$router.push({
					path: menu_path
				});
			}
		},
		/**
		 * @desc: 获取大屏默认绑定
		 */
		getDataViewUrl() {
			const loading = this.$loading();
			return new Promise((resolve, reject) => {
				dataInterface({}, '/api/dataview/getDefault', 'GET')
					.then((res) => {
						if (res.status === 200) {
							resolve(`${process.env.VUE_APP_BASE_URL}pc/dataview/show/${res.data.data}`);
						}
						loading.close();
					})
					.catch((err) => {
						console.log(err, '-----------');
						reject('');
						loading.close();
					});
			});
		},
		/**
		 * @desc: 是否为当前选中路由
		 * @param {Object} menu
		 */
		isCurrentActive(menu) {
			if (!menu) return false;
			const { child_path_list, menu_path } = menu;
			// 降this.currentPath 值改为 fullPath 避免会有同一个path 传参不同  不确定实施是否会有相同路由请客

			if (this.$route.fullPath.includes('pageUUID=')) {
				this.currentPath = this.$route.path;
				if (this.currentPath === menu_path) {
					return true;
				}
			} else {
				if (this.currentPath.includes(menu_path)) {
					return true;
				}
			}

			if (child_path_list && Array.isArray(child_path_list)) {
				for (let i = 0; i < child_path_list.length; i++) {
					if (window.location.href.indexOf(child_path_list[i]) > -1) {
						return true;
					}
				}
			}
			return false;
		},
		/**
		 * @desc: 是否显示基本库配置
		 * @param {String} url
		 */
		showLibrary(url) {
			if (url !== '/child-app/base-data/subitem-library' && url !== '/subitem-data-table') {
				return true;
			}
			const privilegeList = ['蒋冬梅', '蒲亚军'];
			return privilegeList.includes(this.$GetUserInfo('name'));
		},
		/**
		 * @desc: 打开菜单
		 * @param {Object} data 菜单对象
		 */
		onContextMenu(data, e, ele) {
			console.log('-----contextmenu-----');
			console.log(data, e, ele, '-------88888-------');
			e.stopPropagation();
			e.preventDefault();
			const top = e.pageY;
			const left = e.pageX;
			this.$store.commit('showCustomMenu', {
				position: {
					left,
					top
				},
				data
			});
		},
		/**
		 * @desc: 滚动
		 */
		closeMenu() {
			this.$store.commit('hideCustomMenu');
		},
		/**
		 * @desc: 是否显示
		 * 规则： 当前路由或子路由就进行显示
		 * @param {String} url 被对比路由
		 * @param {String} path 当前路由
		 */
		isChildPath(url, path) {
			if (!url || typeof url !== 'string') return false;
			if (url === path) return true;
			const arr = url.split('/');
			return arr.includes(path);
		}
	}
};
