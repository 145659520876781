var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-bar",on:{"click":_vm.onTopBar}},[_c('section',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.systemLogo,"alt":"logo"}})]),(!_vm.isUpdatePwd)?_c('section',{staticClass:"top-right-box"},[_c('div',{staticClass:"select-archi-box"},[(_vm.needAppQrcode)?_c('appQrcode'):_vm._e(),_c('section',{staticClass:"infos"},[_c('UserActions')],1),(_vm.needFiveParties)?_c('Cooperation'):_vm._e(),(_vm.needArchiInfo)?_c('SelectArchi',{attrs:{"reload":true}}):_vm._e()],1),_c('div',{staticClass:"right-box-bottom"},[_c('section',{staticClass:"fix-actions"},[(_vm.systemConfig && _vm.systemConfig.need_second)?_c('a',{staticClass:"btn",attrs:{"href":"javascript:;"},on:{"click":_vm.onHome}},[_c('img',{attrs:{"src":require("@/assets/images/back-home.png"),"alt":"首页"}})]):_vm._e(),(_vm.systemConfig && _vm.systemConfig.need_app_center)?_c('a',{staticClass:"btn app-center",class:{
						active: _vm.showapps
					},attrs:{"href":"javascript:;"},on:{"click":_vm.onAPPCenter}},[_c('img',{attrs:{"src":require("@/assets/images/app-center.png"),"alt":"应用中心"}})]):_vm._e()]),_c('ul',{staticClass:"nav-list"},_vm._l((_vm.topNav),function(item){return (
						!item.is_hide &&
						_vm.judgingArchiAPermission(item.archi_limit, item.permissions, true) &&
						_vm.judgingCustomLogic(item.custom_logics)
					)?_c('li',{key:item.key,staticClass:"nav-item",class:{
						active:
							_vm.currentMenu && (+_vm.currentMenu.id === +item.id || _vm.currentMenu.menu_path === item.menu_path) && !_vm.showapps
					},on:{"click":function($event){return _vm.onTopNav(2, item, true)}}},[(item.icon)?[(item.icon.indexOf('el-icon') === 0)?_c('i',{class:['element-iconfont', item.icon]}):_c('svg',{staticClass:"iconfont-svg",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":'#' + item.icon}})])]:_vm._e(),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$getLocalizedLabel({ zhLabel: item.name, enLabel: item.en_name })))])],2):_vm._e()}),0)])]):_vm._e(),_c('APPCenter',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAppCenter),expression:"showAppCenter"}],on:{"getData":_vm.onUpdateAPPData}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }