<!--
 * @Description: 菜单历史
 * @Author: luocheng
 * @Date: 2021-11-02 11:28:03
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-05 15:54:01
-->
<template>
	<section
		class="history-box"
		:class="systemConfig?.theme_type === 4 ? 'history-box-four' : ''"
		v-if="appNavHistoryList"
	>
		<div class="tab-list">
			<el-tabs v-model="targetHistory" type="card" @tab-remove="onTabRemove" @tab-click="onTabs">
				<el-tab-pane
					:key="index"
					v-for="(item, index) in appNavHistoryList"
					:label="item && item.name"
					:name="item && item.id && item.id.toString()"
					@click="onTab(item)"
					:closable="item && item.menu_path !== indexPath"
				>
					<div
						:class="[
							systemConfig?.theme_type === 4 ? 'tab-pane-label-four tab-pane-label' : 'tab-pane-label',
							index === 0 ? 'first-tab-item' : ''
						]"
						slot="label"
						@contextmenu.prevent="openMenu($event, item)"
					>
						<div class="tab-inner-div">
							<i
								v-if="item && item.icon && item.icon.toString().indexOf('el-icon') === 0"
								:class="item && item.icon"
							></i>
							<i v-else :class="['iconfont', item && item.icon]"></i>
							<span class="tab-span-name">
								{{ item && $getLocalizedLabel({ zhLabel: item.name, enLabel: item.en_name }) }}
							</span>
							<span v-if="systemConfig?.theme_type === 4 && index !== 0" class="border-span-1">
								<span class="inner-circle"></span>
							</span>
							<span v-if="systemConfig?.theme_type === 4" class="border-span-2">
								<span class="inner-circle"></span>
							</span>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
		<ul
			v-if="visible"
			class="contextmenu el-dropdown-menu el-dropdown-menu--small"
			:style="{ left: left + 'px', top: top + 'px' }"
		>
			<li class="el-dropdown-menu__item" @click="onClose('other')">
				<span class="item">
					<i class="el-icon-close"></i>
					<span>{{ $t('components.NavHistory.022115-0') }}</span>
				</span>
			</li>
			<li class="el-dropdown-menu__item" @click="onClose('left')">
				<span class="item">
					<i class="el-icon-back"></i>
					<span>{{ $t('components.NavHistory.022115-1') }}</span>
				</span>
			</li>
			<li class="el-dropdown-menu__item" @click="onClose('right')">
				<span class="item">
					<i class="el-icon-right"></i>
					<span>{{ $t('components.NavHistory.022115-2') }}</span>
				</span>
			</li>
			<li class="el-dropdown-menu__item" @click="onClose('all')">
				<span class="item">
					<i class="el-icon-close"></i>
					<span>{{ $t('components.NavHistory.022115-3') }}</span>
				</span>
			</li>
		</ul>
		<!-- 右侧操作 -->
		<el-dropdown>
			<!-- :class="{ 'header-tabs-more-active': active }" -->
			<span class="header-tabs-more">
				<span class="header-tabs-more-icon">
					<i class="box box-t"></i>
					<i class="box box-b"></i>
				</span>
			</span>
			<el-dropdown-menu slot="dropdown">
				<el-dropdown-item>
					<span class="item" @click="onClose('other')">
						<i class="el-icon-close"></i>
						<span>{{ $t('components.NavHistory.022115-0') }}</span>
					</span>
				</el-dropdown-item>
				<el-dropdown-item>
					<span class="item" @click="onClose('left')">
						<i class="el-icon-back"></i>
						<span>{{ $t('components.NavHistory.022115-1') }}</span>
					</span>
				</el-dropdown-item>
				<el-dropdown-item>
					<span class="item" @click="onClose('right')">
						<i class="el-icon-right"></i>
						<span>{{ $t('components.NavHistory.022115-2') }}</span>
					</span>
				</el-dropdown-item>
				<el-dropdown-item>
					<span class="item" @click="onClose('all')">
						<i class="el-icon-close"></i>
						<span>{{ $t('components.NavHistory.022115-3') }}</span>
					</span>
				</el-dropdown-item>
			</el-dropdown-menu>
		</el-dropdown>
	</section>
</template>

<script>
import { Dropdown, DropdownItem, DropdownMenu, TabPane, Tabs } from 'element-ui';
import { mapState } from 'vuex';
import { openUrl } from '@/utils/tools';
export default {
	name: 'NavHistory',
	components: {
		'el-tabs': Tabs,
		'el-tab-pane': TabPane,
		'el-dropdown': Dropdown,
		'el-dropdown-menu': DropdownMenu,
		'el-dropdown-item': DropdownItem
	},
	data() {
		return {
			targetHistory: '',
			// tab菜单
			visible: false,
			top: 0,
			left: 0,
			// 首页
			indexPath: '',
			// 当前架构类型
			archiType: ''
		};
	},
	computed: {
		...mapState(['appNavHistoryList', 'currentApp', 'appIndexMenu', 'systemConfig'])
	},
	watch: {
		$route: {
			handler(newVal) {
				for (let i = 0; i < this.appNavHistoryList.length; i++) {
					if (!this.appNavHistoryList[i]) break;
					if (this.appNavHistoryList[i].menu_path.includes(newVal.path)) {
						this.targetHistory = this.appNavHistoryList[i]?.id?.toString();
					}
				}
			},
			deep: true,
			immediate: true
		},
		visible(value) {
			if (value) {
				document.body.addEventListener('click', this.closeMenu);
			} else {
				document.body.removeEventListener('click', this.closeMenu);
			}
		},
		// 本应用路由列表
		appRoutes() {
			return this.currentApp?.app_orign_data || [];
		},
		currentApp() {
			this.indexPath = this.getAppIndex(JSON.parse(sessionStorage.getItem('currentApp'))?.app_orign_data);
		}
	},
	created() {
		this.archiType = this.$GetTargetArchi('archiType');
		const history = sessionStorage.getItem('appNavHistoryList');
		// 首页路由
		try {
			// 获取当前应用的首页
			const appIndexPath = this.getAppIndex(JSON.parse(sessionStorage.getItem('currentApp'))?.app_orign_data);
			if (appIndexPath) {
				this.indexPath = appIndexPath;
			} else {
				this.indexPath = this.appIndexMenu ? this.appIndexMenu.menu_path : process.env.VUE_APP_HOME_PATH;
			}
		} catch (err) {
			console.log(err);
			this.indexPath = this.appIndexMenu ? this.appIndexMenu.menu_path : process.env.VUE_APP_HOME_PATH;
		}
		if (history) {
			const historyList = JSON.parse(history);
			this.$store.commit('setAppNavHistory', {
				origin: 5,
				history: historyList
			});
			const targetUrl = this.$route.path;
			for (let i = 0; i < historyList.length; i++) {
				if (!historyList[i]) break;
				if (historyList[i].menu_path === targetUrl) {
					this.targetHistory = historyList[i]?.id?.toString();
				}
			}
		} else {
			// 中控台
			let pageObject = null;
			if (this.$route.path === '/entry') {
				pageObject = this.appIndexMenu;
			} else {
				pageObject = this.getPathObj(this.$route.path, this.appRoutes);
			}
			if (!pageObject) {
				// 直接进入中控台设置首页为历史记录
			}
			this.$store.commit('setAppNavHistory', {
				origin: 6,
				history: pageObject
			});
		}
		this.userInfo = this.$GetUserInfo();
	},
	methods: {
		/**
		 * @description: 获取当前应用首页
		 * @param {Array} list 菜单列表
		 * @return {String} 首页路由
		 */
		getAppIndex(list) {
			for (let i = 0; i < list.length; i++) {
				const item = list[i];
				if (item.is_index) {
					return item.menu_path;
				}
				const { children_list = [] } = item;
				if (Array.isArray(children_list) && children_list.length) {
					let result = this.getAppIndex(children_list);
					if (result) {
						return result;
					}
				}
			}
			return '';
		},
		/**
		 * @desc: 获取父级tabIndex
		 * @param {Array} arr 菜单类表
		 * @param {String} path 路径
		 */
		getParentIndex(arr, path, parent = null) {
			if (!path || !arr || !arr.length) {
				return '';
			}
			let result = '';
			for (let i = 0; i < arr.length; i++) {
				parent = arr[i];
				const pathList = arr[i][this.childPathUUID] || [];
				if (pathList && pathList.length) {
					for (let j = 0; j < pathList.length; j++) {
						if (path.indexOf(pathList) > -1) {
							result = arr[i]?.id?.toString();
							return result;
						}
					}
				}
				const children = arr[i][this.childrenUUID];
				if (children && children.length) {
					result = this.getParentIndex(children, path, parent);
					if (result) {
						return result;
					}
				}
			}
			return result;
		},
		/**
		 * @desc: 移除tab
		 * @param {Object} data
		 */
		onTabRemove(data) {
			const index = this.appNavHistoryList.findIndex((ele) => +ele.id === +data);
			if (+data === +this.targetHistory) {
				// 删除当前节点之后需要打开新页面
				if (this.appNavHistoryList.length > 1) {
					if (index === this.appNavHistoryList.length - 1) {
						// 删除最后一个
						this.onTab(this.appNavHistoryList[index - 1]);
					} else if (index < this.appNavHistoryList.length - 1) {
						// 删除0 - len - 1
						this.onTab(this.appNavHistoryList[index + 1]);
					}
				} else {
					// 跳转首页
					if (this.appIndexMenu) {
						this.onTab(this.appIndexMenu);
					}
				}
			}
			this.$store.commit('removeAppNavHistory', index);
		},
		/**
		 * @desc: 点击tab
		 */
		onTabs() {
			const pageObj = this.appNavHistoryList.find((ele) => +ele.id === +this.targetHistory);
			this.onTab(pageObj);
		},
		/**
		 * @desc: 获取路由对象
		 */
		getPathObj(path, list = []) {
			let result = null;
			result = list.find((ele) => ele.menu_path === path);
			if (result) {
				return result;
			}
			for (let i = 0; i < list.length; i++) {
				const { children_list } = list[i];
				if (children_list && Array.isArray(children_list) && children_list.length) {
					result = this.getPathObj(path, children_list);
					if (result) {
						return result;
					}
				}
			}
			return result;
		},
		/**
		 * @desc: 点击某个节点
		 * @param {Object} pageObj
		 */
		onTab(pageObj) {
			const { menu_type, menu_path, link, link_type } = pageObj;
			this.targetHistory = pageObj?.id?.toString() || '';
			if ([0, 1, 3, 4].includes(menu_type)) {
				this.$store.commit('setAppNavHistory', {
					origin: 7,
					history: pageObj
				});
			}
			if (menu_type === 0) {
				// 页面
				if (this.$route.path === menu_path) return;
				// 跳转页面
				this.$router.push({
					path: menu_path,
					query: {
						// pageId: pageObj[this.pageIDUUID], 暂时无用
						pageUUID: pageObj.page_uuid
					}
				});
			} else if (menu_type === 1) {
				// 外链
				if (link_type === '_blank') {
					// window.open(link);
					openUrl(link, link);
				} else if (link_type === '_self') {
					window.location.href = link;
				} else {
					// window.open(link);
					openUrl(link, link);
				}
			} else if (menu_type === 3) {
				// 弃用
				if (this.$route.path === menu_path) return;
				// 微前端页面
				this.$router.push({
					path: menu_path,
					query: {
						type: 'micro'
					}
				});
			} else if (menu_type === 4) {
				// 微应用及手写页面
				// console.log('仅跳转页面')
				if (this.$route.path === menu_path) return;
				this.$router.push({
					path: menu_path
				});
			}
		},
		/**
		 * @desc: 关闭
		 * @param {String} type all left right other
		 */
		onClose(type) {
			const resultArr = [];
			if (type === 'all') {
				// 所有
				if (this.appIndexMenu) {
					resultArr.push(this.appIndexMenu);
					this.onTab(this.appIndexMenu);
				}
			} else if (type === 'other') {
				// 其他
				resultArr.push(this.appNavHistoryList.find((ele) => +ele.id === +this.targetHistory));
			} else if (type === 'left') {
				// 左侧
				const index = this.appNavHistoryList.findIndex((ele) => +ele.id === +this.targetHistory);
				for (let i = 0; i < this.appNavHistoryList.length; i++) {
					if (i >= index) {
						resultArr.push(this.appNavHistoryList[i]);
					}
				}
			} else if (type === 'right') {
				// 右侧
				const index = this.appNavHistoryList.findIndex((ele) => +ele.id === +this.targetHistory);
				for (let i = 0; i < this.appNavHistoryList.length; i++) {
					if (i <= index) {
						resultArr.push(this.appNavHistoryList[i]);
					}
				}
			}
			this.$store.commit('setAppNavHistory', {
				origin: 8,
				history: resultArr
			});
			this.closeMenu();
		},
		/**
		 * @desc: tab 菜单
		 * @param {Object} e
		 * @param {Object} item
		 */
		openMenu(e) {
			const offsetLeft = this.$el.getBoundingClientRect().left;
			const offsetWidth = this.$el.offsetWidth;
			const maxLeft = Math.round(offsetWidth);
			const left = Math.round(e.clientX - offsetLeft);
			if (left > maxLeft) this.left = maxLeft;
			else this.left = left;
			this.top = Math.round(e.clientY - 60);
			this.visible = true;
		},
		/**
		 * @desc: 关闭操作菜单
		 */
		closeMenu() {
			this.visible = false;
		}
	}
};
</script>

<style lang="less" scoped>
.history-box {
	position: relative;
	width: 100%;
	box-sizing: border-box;
	padding: 7px 20px 0 0px;
	height: 47px;
	box-sizing: border-box;
	padding-right: 50px;
	display: flex;
	user-select: none;
	.tab-list {
		flex: 1;
		width: 100%;
		height: 100%;
		.el-tabs--card > .el-tabs__header {
			border: none;
		}
		:deep(.el-tabs) {
			box-sizing: border-box;
			.el-tabs__header {
				border: none !important;
				margin: 0;
			}
			.el-tabs__content {
				display: none;
			}
			.el-tabs__nav {
				height: 40px;
				border: none;
			}
			.el-tabs__item {
				box-sizing: border-box;
				// margin: 0 5px;
				margin-top: 2px;
				height: 100%;
				box-sizing: border-box;
				line-height: 38px;
				font-family: PingFangSC, PingFangSC-Medium;
				border: none;
				transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
				margin-right: -18px;
				padding: 0 30px 0 30px;
				.tab-pane-label {
					display: inline-block;
					height: 36px;
					overflow: hidden;
					.iconfont {
						color: #303133;
					}
					i {
						vertical-align: top;
						color: #272829;
						line-height: 38px;
						font-size: 16px;
						margin-right: 5px;
					}
					span {
						vertical-align: top;
						line-height: 38px;
						font-size: 14px;
						color: #272829;
					}
				}
				&:hover {
					padding: 0 30px 0 30px;
					color: var(--themeColor);
					background: #dee1e6;
					mask: url('~@/assets/images/layout/smoot-tab.png');
					mask-size: 100% 100%;
				}
				&.is-active {
					padding: 0 30px 0 30px;
					color: var(--themeColor);
					// background: #e8f4ff;
					background: #f2f4f8;
					outline: none;
					mask: url('~@/assets/images/layout/smoot-tab.png');
					mask-size: 100% 100%;
					font-weight: bold;
					.tab-pane-label {
						i {
							color: var(--themeThreeColor);
						}
						span {
							color: var(--themeThreeColor) !important;
						}
					}
					.el-icon-close {
						color: var(--themeThreeColor) !important;
					}
				}
			}
			.el-icon-close {
				margin-top: -22px;
				color: #272829;
				font-size: 16px;
			}
			.el-tabs__nav-prev {
				line-height: 40px;
			}
			.el-tabs__nav-next {
				line-height: 40px;
			}
			.el-tabs__new-tab {
				display: none;
			}
		}
	}
	.contextmenu {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		user-select: none;
	}
}
</style>
<style lang="less">
.history-box {
	// box-shadow: 0 1px 4px rgba(0, 21, 14, 0.8);
	// box-shadow: 5px 5px 20px 5px rgba(72, 134, 255, 0.1);
	position: relative;
	user-select: none;
	.el-dropdown {
		position: absolute;
		right: 16px;
		top: 19px;
		cursor: pointer;
		transition: all 0.3s;
		user-select: none;
		&:hover {
			transform: rotate(90deg);
			.box-t {
				&:before {
					transform: rotate(45deg) !important;
				}
			}
			.box:before,
			.box:after {
				background: var(--themeColor) !important;
			}
		}
	}
	.header-tabs-more {
		.header-tabs-more-icon {
			transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s, color 0.1s, font-size 0s;
			// transform: rotate(90deg);
			.box-t {
				&:before {
					transform: rotate(0deg);
				}
			}
			.box:before,
			.box:after {
				background: #9a9a9a;
			}
			.box {
				position: relative;
				display: block;
				width: 14px;
				height: 8px;
				&:before {
					position: absolute;
					top: 2px;
					left: 0;
					width: 6px;
					height: 6px;
					content: '';
					background: #9a9a9a;
				}
				&:after {
					position: absolute;
					top: 2px;
					left: 8px;
					width: 6px;
					height: 6px;
					content: '';
					background: #9a9a9a;
				}
			}
			.box-t {
				&:before {
					transition: transform 0.3s ease-out 0.3s;
				}
			}
		}
	}
	.el-dropdown-menu__item {
		user-select: none !important;
	}
}
.history-box-four.history-box {
	background: #f9f6f4 !important;
	border-radius: 20px 0 0 0;
	padding: 0px 50px 0 0;
	height: 48px;
	.tab-list {
		overflow: hidden;
		.el-tabs {
			.el-tabs__nav {
				height: 48px;
			}
			.tab-inner-div {
				margin-top: 0 !important;
				line-height: 48px;
				text-align: left;
			}
			.el-tabs__item {
				overflow: hidden;
				margin: 0;
				position: relative;
				padding: 0 !important;
				.tab-pane-label.tab-pane-label-four {
					height: 48px;
					line-height: 48px;
					padding: 0 18px;
					.tab-span-name {
						line-height: 48px;
						color: #7a8599;
						font-weight: 400;
					}
					.iconfont,
					i {
						color: #7a8599;
						line-height: 48px;
						font-size: 16px;
						font-weight: 400;
					}
				}
				&:hover {
					mask: none !important;
					background-color: transparent;
					transform: rotate(0deg);
					margin: 0 !important;
					i {
						color: #e46445 !important;
						font-weight: 400;
					}
					.tab-span-name {
						color: #e46445 !important;
						font-weight: 400;
					}
					.el-icon-close {
						right: 0px !important;
					}
				}
				&.is-active {
					background-color: transparent !important;
					mask: none !important;
					margin: 0 !important;
					padding: 0 !important;
					.tab-inner-div {
						padding: 0 34px 0 24px;
						background-color: #fff;
						border-radius: 20px 20px 0 0;
						position: relative;
						i {
							color: #e46445 !important;
						}
						.tab-span-name {
							color: #e46445 !important;
						}
					}
					.border-span-1 {
						width: 20px;
						height: 20px;
						display: inline-block;
						overflow: hidden;
						position: absolute;
						left: -20px;
						bottom: 0;
						background: #fff;
						.inner-circle {
							display: inline-block;
							border-radius: 50%;
							background: #f9f6f4;
							width: 42px;
							height: 42px;
							margin-left: -21px;
							margin-top: -21px;
						}
					}
					.border-span-2 {
						width: 20px;
						height: 20px;
						display: inline-block;
						overflow: hidden;
						position: absolute;
						right: -20px;
						bottom: 0;
						background: #fff;
						.inner-circle {
							display: inline-block;
							border-radius: 50%;
							background: #f9f6f4;
							width: 42px;
							height: 42px;
							margin-right: -21px;
							margin-top: -21px;
						}
					}
					.el-icon-close {
						color: #8991a2 !important;
						right: 30px !important;
					}
				}
				.el-icon-close {
					color: #8991a2 !important;
					position: absolute !important;
					margin-top: 0;
					top: 17px;
					right: 30px;
				}
				.el-icon-close:hover {
					background: none !important;
				}
			}
		}
	}
	.first-tab-item {
		padding-left: 0 !important;
		.tab-inner-div {
			padding-left: 18px;
		}
	}
}
</style>
