<!--
 * @Description: 左侧主要菜单
 * @Author: luocheng
 * @Date: 2021-10-30 14:39:36
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-26 17:12:35
-->
<template>
	<div class="menu-part-three">
		<i class="icon-box" :class="['iconfont', toggleIcon]" @click="onToggleNav(null)"></i>
		<div class="nav-one-list" style="height: calc(100vh - 100px)" @scroll="onScroll">
			<!-- eslint-disable -->
			<div
				class="menu-item"
				v-for="item in menuList"
				:key="item.id"
				:class="{
					active: activeObject && item.id === activeObject.id
				}"
				v-if="
					judgingArchiAPermission(item.archi_limit, item.permissions) &&
					!item.is_hide &&
					judgingCustomLogic(item.custom_logics)
				"
				@click="onMenu(item)"
				@contextmenu="onContextMenu(item, $event)"
			>
				<i
					v-if="item.icon && item.icon.toString().indexOf('el-icon') === 0"
					:class="item.icon || 'el-icon-warning-outline'"
				>
				</i>
				<i v-else :class="['iconfont', item.icon || 'iconyeqian']"></i>
				<strong>{{ $getLocalizedLabel({ zhLabel: item.name, enLabel: item.en_name }) }}</strong>
				<div class="cover-bg"></div>
			</div>
		</div>
	</div>
</template>

<script>
import partMixin from './partMixin';

export default {
	name: 'MenuPart',
	mixins: [partMixin]
};
</script>

<style lang="less" scoped>
.menu-part-three {
	width: 64px;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	// box-shadow: 3px 0px 6px -2px rgba(0, 0, 0, 8%);
	user-select: none;
	box-sizing: border-box;
	background: #fff;
	box-shadow: inset -1px 0px 0px #eff1f5;
	.icon-box {
		height: 48px;
		padding: 8px 16px;
		line-height: 32px;
		font-size: 20px;
		cursor: pointer;
		box-sizing: border-box;
		text-align: center;
		color: #c1c7cd;
	}
	.nav-one-list {
		width: 100%;
		flex: 1;
		padding: 0 6px 8px 6px;
		box-sizing: border-box;
		overflow: hidden;
		overflow-y: auto;
		.menu-item {
			position: relative;
			width: 52px;
			min-height: 54px;
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			justify-content: space-between;
			padding: 6px;
			padding-top: 8px;
			cursor: pointer;
			margin-bottom: 8px;
			border-radius: 6px;
			&.active {
				background: #f4f6fa;
				i,
				strong {
					color: var(--themeThreeColor);
				}
			}
			&:hover {
				background: #f4f6fa;
				i,
				strong {
					color: var(--themeThreeColor);
				}
			}
			i {
				width: 24px;
				height: 24px;
				margin: 0 auto;
				font-size: 24px;
				line-height: 24px;
				text-align: center;
				color: #71787f;
			}
			strong {
				line-height: 16px;
				width: 125%;
				margin-left: -12.5%;
				font-size: 12.5px;
				text-align: center;
				color: #71787f;
				font-family: 'Source Han Sans CN';
				transform: scale(0.8);
				overflow: hidden;
				word-break: break-all;
			}
		}
	}
}
::-webkit-scrollbar {
	width: 0px !important;
	height: 0px !important;
	background-color: transparent;
	scrollbar-width: none;
}
</style>
