var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-part-three"},[_c('i',{staticClass:"icon-box",class:['iconfont', _vm.toggleIcon],on:{"click":function($event){return _vm.onToggleNav(null)}}}),_c('div',{staticClass:"nav-one-list",staticStyle:{"height":"calc(100vh - 100px)"},on:{"scroll":_vm.onScroll}},_vm._l((_vm.menuList),function(item){return (
				_vm.judgingArchiAPermission(item.archi_limit, item.permissions) &&
				!item.is_hide &&
				_vm.judgingCustomLogic(item.custom_logics)
			)?_c('div',{key:item.id,staticClass:"menu-item",class:{
				active: _vm.activeObject && item.id === _vm.activeObject.id
			},on:{"click":function($event){return _vm.onMenu(item)},"contextmenu":function($event){return _vm.onContextMenu(item, $event)}}},[(item.icon && item.icon.toString().indexOf('el-icon') === 0)?_c('i',{class:item.icon || 'el-icon-warning-outline'}):_c('i',{class:['iconfont', item.icon || 'iconyeqian']}),_c('strong',[_vm._v(_vm._s(_vm.$getLocalizedLabel({ zhLabel: item.name, enLabel: item.en_name })))]),_c('div',{staticClass:"cover-bg"})]):_vm._e()}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }