<!--
 * @Description: 菜单列表
 * @Author: luocheng
 * @Date: 2021-10-30 13:52:21
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-06 11:23:38
-->
<template>
	<article
		class="child-nav-three"
		:style="{
			width: showMenuList && menuList.length ? '216px' : '0px',
			padding: showMenuList && menuList.length ? '0px' : '0px'
		}"
	>
		<header class="child-header">
			<div class="name">
				<!-- <img src="@/assets/images/menu-icon.png" alt=""> -->
				<i
					v-if="menuIcon && menuIcon.toString().indexOf('el-icon') === 0"
					:class="menuIcon || 'el-icon-warning-outline'"
				>
				</i>
				<i v-else :class="['iconfont', menuIcon || 'iconyeqian']"></i>
				<el-tooltip class="item" :content="menuName" placement="top" v-if="menuName && menuName.length > 6">
					<h4 class="hidden-part">{{ menuName }}</h4>
				</el-tooltip>
				<h4 v-else>{{ menuName }}</h4>
			</div>
		</header>
		<article class="menu-box" @scroll="closeMenu">
			<div class="menu-list" v-if="menuList && menuList.length">
				<!-- 菜单默认收起@忠哥 -->
				<!-- eslint-disable -->
				<el-menu default-active="" class="menu-main" :default-openeds="defaultOpened">
					<!-- 带下级 -->
					<el-submenu
						v-if="
							item.menu_type === 2 &&
							!item.is_hide &&
							judgingArchiAPermission(item.archi_limit, item.permissions) &&
							judgingCustomLogic(item.custom_logics)
						"
						:index="item.menu_path"
						v-for="item in menuList"
						:key="item.id"
						:style="{
							paddingLeft: '0px'
						}"
					>
						<template slot="title">
							<i
								class="group-icon"
								:class="item.icon"
								v-if="item.icon && item.icon.toString().indexOf('el-icon') === 0"
							></i>
							<i v-else class="group-icon" :class="['iconfont', item.icon || 'iconyeqian']"></i>
							<span class="group-title">{{ item.name }}</span>
						</template>
						<span v-for="child in item.children_list" :key="child.id">
							<el-menu-item
								:index="child.menu_path || child.link"
								@click="onItem(child)"
								@contextmenu="onContextMenu(child, $event)"
								v-if="
									!child.is_hide &&
									judgingArchiAPermission(child.archi_limit, child.permissions) &&
									judgingCustomLogic(child.custom_logics)
								"
								:class="[isCurrentActive(child) ? 'is-active' : 'without-active']"
							>
								<!-- :data-config="JSON.stringify(child)" -->
								<!-- {
									'is-active': isCurrentActive(child),
									'without-active': !isCurrentActive(child)
								}" -->
								<template slot="title">
									<i
										:class="['item-icon', child.icon]"
										v-if="child.icon && child.icon.toString().indexOf('el-icon') === 0"
									></i>
									<i v-else :class="['item-icon', 'iconfont', child.icon || 'iconyeqian']"></i>
									<span :title="child?.name?.length > 8 ? child.name : ''">
										{{ $getLocalizedLabel({ zhLabel: child?.name, enLabel: child?.en_name }) }}
									</span>
									<!-- <p class="item-text" :class="{
											'over-menu-item-text':  child?.name?.length > 8
										}">
										</p> -->
								</template>
							</el-menu-item>
						</span>
					</el-submenu>
					<!-- 不带下级 -->
					<el-menu-item
						:style="{
							paddingLeft: '11px'
						}"
						v-else-if="
							!item.is_hide &&
							judgingArchiAPermission(item.archi_limit, item.permissions) &&
							judgingCustomLogic(item.custom_logics)
						"
						:index="item.menu_path || item.link"
						@click="onItem(item)"
						@contextmenu="onContextMenu(item, $event)"
						:class="[isCurrentActive(item) ? 'is-active' : 'without-active']"
					>
						<!-- :data-config="JSON.stringify(item)" -->
						<!-- :class="{
							'is-active': isCurrentActive(item),
							'without-active': !isCurrentActive(item)
						}" -->
						<i :class="['item-icon', item.icon]" v-if="item.icon && item.icon.toString().indexOf('el-icon') === 0"></i>
						<i :class="['item-icon', 'iconfont', item.icon || 'iconyeqian']" v-else></i>
						<span slot="title">{{ $getLocalizedLabel({ zhLabel: item?.name, enLabel: item?.en_name }) }}</span>
					</el-menu-item>
				</el-menu>
			</div>
		</article>
	</article>
</template>

<script>
import listMixin from './listMixin';

export default {
	name: 'MenuList',
	mixins: [listMixin],
	mounted() {
		this.resetPadding();
	},
	updated() {
		this.resetPadding();
	},
	methods: {
		/**
		 * @desc: 设置左边距
		 */
		resetPadding() {
			this.$nextTick(() => {
				try {
					const menuTitle = document.querySelectorAll('.el-submenu__title');
					if (menuTitle) {
						menuTitle.forEach((ele) => {
							ele.style.paddingLeft = '11px';
						});
					}
					// 子集
					const menuChildren = document.querySelectorAll('.el-submenu .el-menu .el-menu-item');
					if (menuChildren.length) {
						menuChildren.forEach((ele) => {
							ele.style.paddingLeft = '32px';
						});
					}
				} catch (e) {
					console.log(e, '--000');
				}
			});
		}
	}
};
</script>

<style lang="less" scoped>
@itemHeight: 32px;
.child-nav-three {
	width: 216px;
	box-sizing: border-box;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	transition: all 0.2s;
	box-shadow: -1px 0px 6px rgba(22, 28, 31, 0.04);
	background: var(--el-color-white);
	user-select: none;
	.child-header {
		min-height: 48px;
		width: 100%;
		box-sizing: border-box;
		padding: 8px 12px 7px 12px;
		border-bottom: 1px solid #f2f4f8;
		.name {
			display: flex;
			width: 100%;
			box-sizing: border-box;
			padding: 5px 0 5px 7px;
			i {
				width: 18px;
				height: 18px;
				box-sizing: border-box;
				margin-top: 2px;
				font-size: 14px;
				background: var(--listIconLinearGradient);
				border-radius: 3.375px;
				color: #ffffff;
				padding: 2.25px;
				text-align: center;
				line-height: 14px;
			}
			.el-tooltip {
				flex: 1;
			}
			h4 {
				flex: 1;
				box-sizing: border-box;
				padding-left: 7px;
				min-height: 22px;
				line-height: 22px;
				letter-spacing: 0.5px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-size: 14px;
				font-family: var(--systemFontFamily);
				font-weight: 500;
				text-align: left;
				color: #161c1f;
				&.hidden-part {
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
	}
	.menu-box {
		flex: 1;
		overflow: hidden;
		width: 100%;
		overflow-y: auto;
		box-sizing: border-box;
		padding: 8px;
		.menu-list {
			height: 100%;
			width: 100%;
			box-sizing: border-box;
			:deep(.el-menu) {
				width: 100%;
				border: none !important;
				.el-menu-item {
					height: @itemHeight;
					line-height: @itemHeight;
					text-align: left;
					min-width: 200px !important;
					display: flex;
					padding: 5px 8px 5px 12px;
					margin-bottom: 4px;
					border-radius: 6px;
					.item-icon {
						line-height: 22px;
						margin-right: 8px;
					}
					& > span {
						line-height: 22px;
						flex: 1;
						// width: 120px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						p {
							// display: inline-block;
							// width: auto;
						}
					}
					&.is-active {
						background: var(--themeThreeColor);
						color: #ffffff;
					}
					&.is-active:hover {
						background: var(--themeThreeColor);
					}
					&.without-active {
						background: #fff !important;
						color: #303133 !important;
						.item-icon {
							color: #909399 !important;
						}
					}
					&:hover {
						background: var(--menuHoverColor);
						// &>span {
						// 	.over-menu-item-text {
						// 		animation: moveMenuItem 1.5s infinite alternate linear;
						// 	}
						// }
					}
				}
				.el-submenu__title {
					height: @itemHeight;
					// line-height: @itemHeight;
					text-align: left;
					margin-bottom: 4px;
					display: flex;
					padding: 5px 8px 5px 12px;
					border-radius: 6px;
					.group-icon {
						color: #161c1f;
						line-height: 22px;
						margin-right: 8px;
					}
					.group-title {
						flex: 1;
						line-height: 22px;
						// margin-right: 10px;
					}
					&:hover {
						background: var(--menuHoverColor);
						color: #fff !important;
						i {
							color: #fff !important;
						}
					}
				}
			}
		}
	}
}
::-webkit-scrollbar {
	width: 0px;
	height: 0px;
	background-color: transparent;
}
:deep(.el-submenu__icon-arrow) {
	right: 8px !important;
}
// @keyframes moveMenuItem {
// 	0% {
// 		transform: translate(0, 0);
// 	}
// 	100% {
// 		transform: translate(calc(-100% + 120px), 0);
// 	}
// }
</style>
<style>
.navlist-popper {
	background: rgba(0, 0, 0, 0.6) !important;
	color: #fff !important;
	width: auto !important;
	min-width: 80px !important;
	padding: 5px !important;
	padding: 5px !important;
	font-size: 12px !important;
}
.navlist-popper > .popper__arrow {
	display: none;
}
</style>
